
import '../App.css';
import { Routes, Route, Link } from "react-router-dom";
import Homeintermediary from './homeintermediary';
import Insurance from './insurance';
import InsuranceToCheck from './insurancetocheck'
import Login from './login'
import Renewed from './renewed';
function Paths() {
    return (
        <Routes>
            <Route path="/intermediario" element={<Homeintermediary />} />
            <Route path="/polizas/*" element={<Insurance />} />
            <Route path="/renovar/*" element={<InsuranceToCheck />} />
            <Route path="/renovadas/*" element={<Renewed />} />
            <Route path="/" element={<Login />} />
            <Route path="*" element={<NoMatch />} />
        </Routes>
    )

}

/* function App() {
    return (
        <div className="App">
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo" />
                <p>
                    Edit <code>src/App.js</code> and save to reload.
                </p>
                <a
                    className="App-link"
                    href="https://reactjs.org"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Learn React
                </a>
            </header>
        </div>
    );
} */



function NoMatch() {
    return (
        <div id="nomatchcontainer">
            <h2>Página no encontrada, por favor revisar la dirección</h2>
            <p>
                <Link to="/">Ir a pagina principal</Link>
            </p>
        </div>
    );
}

export default Paths;
