import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useEffect, useState } from "react"

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

export default function Login(params) {
    const [email, setemail] = useState("");
    const [password, setpassword] = useState("");

    useEffect(() => {
        if(sessionStorage.getItem("accessToken") !== null){
            window.location.href = "/polizas"
        }
    }, []);
    const handleKeyDown = (e) => { if (e.key === 'Enter') { startLogin(); } };
    const startLogin = () => {
        if ((email === "") || (password.length === 0)) {
            alert("Por favor completa los datos necesarios")
        } else {

            const auth = getAuth();
            signInWithEmailAndPassword(auth, email, password,)
                .then((userCredential) => {
                    // Signed in 
                    const user = userCredential.user;
                    sessionStorage.setItem("accessToken", user.accessToken)
                    sessionStorage.setItem("email", user.email)
                    window.location.href = "/polizas"
                    // ...
                })
                .catch((error) => {
                    const errorMessage = error.message;
                    alert(errorMessage)
                    // ..
                });
        }
    }

    return (
        <>
        <h1 style={{textAlign: "center", color: "rgb(59 124 216)", marginBottom: "1rem", marginTop: "-2rem"}}>RENOVACIONES</h1>
            <div id="loginContainer">
                    
                <div id="loginForm">
                    <div className="mb-3">

                        <TextField
                            label="Correo"
                            className="roundedBorder"
                            color="info"
                            onChange={(e) => { setemail(e.target.value) }}
                            value={email}
                            id="EmailInput"
                            placeholder="name@example.com"
                            margin="dense"
                            size="small"
                            style={{margin: "1rem", display: "flex"}}
                        />

                    </div>
                    <div className="mb-3" style={{ marginTop: "1rem",  }}>
                        <TextField
                            label="Contraseña"
                            className="roundedBorder"
                            color="info"
                            type="password"
                            onChange={(e) => { setpassword(e.target.value) }}
                            value={password}
                            id="passwordInput"
                            margin="dense"
                            size="small"
                            placeholder="**********"
                            onKeyDown={(e)=>{handleKeyDown(e)}}
                            style={{margin: "1rem", display: "flex"}}
                        />
                    </div>
                    <div style={{ display: "flex", marginTop: "2rem" }}>
                        <Button onClick={startLogin} size="small" style={{ margin: "auto",  borderRadius: "4rem", fontSize: "1.2rem"}} color="info" variant="contained">Iniciar</Button>

                    </div>
                </div>
            </div>

        </>
    )
}