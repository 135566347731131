import React, { Component } from "react";
import TextField from "@mui/material/TextField";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import "../assets/css/homeintermediary.css";
import SendIcon from "@mui/icons-material/Send";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import MuiTable from "../components/MuiTable"
const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 400,
	bgcolor: "background.paper",
	border: "2px solid #266ED4",
	boxShadow: 24,
	borderRadius: "1rem",
	p: 4,
};

const stylealert = {
	position: "absolute",
	top: "50%",
	left: "50%",
	display: "flex",
	flexDirection: "row",
	transform: "translate(-50%, -50%)",
	bgcolor: "#0D3C9D",
	border: "2px solid #266ED4",
	boxShadow: 24,
	borderRadius: "5rem",
	color: "white",
	padding: "1rem 2rem",
};

export default class HomeIntermdeiary extends Component {
	constructor(props) {
		super(props);

		this.state = {
			text: "",
			datatohandle: [],
			responsejson: [],
			defaultResult: [],
			showModal: false,
			showModalb: false,
			showalert: false,
		};

		this.handleSelectedData = this.handleSelectedData.bind(this);
	}

	handleSelectedData(data) {
		this.setState({ datatohandle: data });
	}

	handletext(event) {
		this.setState({ text: event.target.value });
	}

	handleKeyDown(e) {
		if (e.key === "Enter") {
			this.search();
		}
	}

	modalHandle() {
		this.setState({ showModal: !this.state.showModal });
	}
	search() {
		this.setState({
			responsejson: this.state.defaultResult,
		});
		var result = [];
		if (this.state.text.length > 0) {
			for (let x = 0; x < this.state.responsejson.length; x++) {
				const element = this.state.responsejson[x];

				var asegurado = this.state.text.replace(/[^a-zA-Z ]/g, "");
				var myRegExp = new RegExp("" + asegurado, "i");

				if (element.asegurado.match(myRegExp) !== null) {
					result.push(element);
				} else if (element.intermediario.match(myRegExp) !== null) {
					result.push(element);
					// eslint-disable-next-line
				} else if (element.poliza == this.state.text) {
					result.push(element);
				}
				if (x === this.state.responsejson.length - 1) {
					this.setState({ responsejson: result });
				}
			}
		}
	}
	simulatealert() {
		for (let x = 0; x < this.state.datatohandle.length; x++) {
			const elementa = this.state.datatohandle[x];
			console.log(this.state.datatohandle[x])

			fetch(`${process.env.REACT_APP_DOMAIN}/renovacion/update/solicitado/${elementa.renovacionId}`, {
				"body": null,
				"method": "PUT",
				"credentials": "omit"
			});
			if (x === this.state.datatohandle.length - 1) {
				this.setState({ showModal: !this.state.showModal }, () => {
					setTimeout(() => {
						this.setState({ showalert: true }, () => {
							setTimeout(() => {
								this.setState({ showalert: false }, () => {
									window.location.reload();
								});
							}, 3000);
						});
					}, 1000);
				});
			}
		}
	}

	modalHandleb() {
		this.setState({ showModalb: !this.state.showModalb });
	}
	handleIntermediaryCode(event) {
		this.setState({ IntermediaryCode: event.target.value });
	}
	handleKeyDownIntermediary(e) {
		if (e.key === "Enter") {
			this.search();
		}
	}

	getdata() {
		let that = this;
		fetch(`${process.env.REACT_APP_DOMAIN}/renovacion/enviado/${this.state.IntermediaryCode}`, {
			"method": "GET",
			"mode": "cors",
			"credentials": "omit"
		}).then(response => response.json()).then(data => {
			if (data.length < 1) {
				alert("No hay pólizas con este código")
			} else {
				var thedata = data.map((poliza) => {
					return {
						poliza: poliza.poliza,
						asegurado: poliza.asegurado,
						intermediario: poliza.intermediario,
						telefono: poliza.telefono,
						inicio: poliza.inicio,
						final: poliza.fechaFinal,
						"prima bruta": poliza.primaBruta,
						impuestos: poliza.impuestos,
						"prima neta": poliza.primaNeta,
						renovacionId: poliza.renovacionId
					}
				})
				that.setState({
					responsejson: thedata,
					defaultResult: thedata,
				})

			}
		}).catch((error) => {
			console.log(error)
		});;
	}

	componentDidMount() {
		let that = this;
		if (window.location.search.split('?code=')[1] === undefined) {
			that.setState({ showModalb: true });
		} else {
			fetch(`${process.env.REACT_APP_DOMAIN}/renovacion/enviado/${window.location.search.split('?code=')[1]}`, {
				"method": "GET",
				"mode": "cors",
				"credentials": "omit"
			}).then(response => response.json()).then(data => {
				if (data.length < 1) {
					alert("No hay pólizas con este código")
				} else {
					var thedata = data.map((poliza) => {
						return {
							poliza: poliza.poliza,
							asegurado: poliza.asegurado,
							intermediario: poliza.intermediario,
							telefono: poliza.telefono,
							inicio: poliza.inicio,
							final: poliza.fechaFinal,
							"prima bruta": poliza.primaBruta,
							impuestos: poliza.impuestos,
							"prima neta": poliza.primaNeta,
							renovacionId: poliza.renovacionId
						}
					})
					that.setState({
						responsejson: thedata,
						defaultResult: thedata,
					})

				}
			});
		}
	}
	sendIt() {
		this.modalHandle();
	}
	render() {
		return (
			<>
				<Modal
					open={this.state.showModalb}
					onClose={() => {
						this.modalHandleb();
					}}
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description"
				>
					<Box sx={style}>
						<Typography
							id="modal-modal-title"
							variant="h6"
							component="h2"
							style={{ textAlign: "center" }}
						>
							Código de intermediario
						</Typography>
						<Typography
							id="modal-modal-description"
							sx={{
								mt: 2,
								display: "flex",
								justifyContent: "space-around",
							}}
						>
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
								}}
							>
								<TextField
									label="Código"
									size="small"
									value={this.state.IntermediaryCode}
									onChange={this.handleIntermediaryCode.bind(
										this
									)}
									margin="dense"
									onKeyDown={this.handleKeyDownIntermediary.bind(
										this
									)}
									className="roundedBorder"
								/>
								<SearchRoundedIcon
									onClick={() => {
										this.getdata();
									}}
									className="SearchIcon"
								/>
							</div>
						</Typography>
					</Box>
				</Modal>
				<Modal
					open={this.state.showModal}
					onClose={() => {
						this.modalHandle();
					}}
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description"
				>
					<Box sx={style}>
						<Typography
							id="modal-modal-title"
							variant="h6"
							component="h2"
						>
							Está seguro de generar este listado?
						</Typography>
						<Typography
							id="modal-modal-description"
							sx={{
								mt: 2,
								display: "flex",
								justifyContent: "space-around",
							}}
						>
							<Button
								variant="contained"
								color="success"
								onClick={() => {
									this.simulatealert();
								}}
							>
								Si
							</Button>
							<Button
								variant="contained"
								color="error"
								onClick={() => {
									this.modalHandle();
								}}
							>
								No
							</Button>
						</Typography>
					</Box>
				</Modal>
				<Modal
					open={this.state.showalert}
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description"
				>
					<Box sx={stylealert}>
						<Typography
							id="modal-modal-title"
							variant="h6"
							component="h2"
						>
							Pólizas enviadas
						</Typography>
						<CheckCircleOutlineIcon
							style={{ fontSize: "2rem", marginLeft: "1rem" }}
						/>
					</Box>
				</Modal>

				<div>
					<h1 style={{ color: "#0e3e9b" }}>Pólizas a Renovar</h1>
				</div>
				<div id="navBar">
					<div id="SearchBar">
						<div
							style={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
							}}
						>
							<TextField
								label="Buscar"
								size="small"
								value={this.state.text}
								onChange={this.handletext.bind(this)}
								margin="dense"
								onKeyDown={this.handleKeyDown.bind(this)}
								className="roundedBorder"
							/>
							<SearchRoundedIcon
								onClick={() => {
									this.search();
								}}
								className="SearchIcon"
							/>
						</div>
					</div>
				</div>
				<div id="insurancesContainer">
					{
						this.state.responsejson.length > 0 ?
							<MuiTable json={this.state.responsejson} sendSelectedData={(element) => { this.setState({ datatohandle: element }) }} /> :
							<MuiTable sendSelectedData={console.log}
								json={[{
									poliza: "",
									asegurado: "",
									intermediario: "",
									telefono: "",
									estado: "",
									inicio: "",
									final: "",
									"prima bruta": "",
									impuestos: "",
									"prima neta": "",
									renovacionId: ""
								}]}
							/>
					}
					<div
						id="sendButton"
						className="buttoncursor"
						onClick={() => {
							this.sendIt();
						}}
					>
						<p>Enviar Pólizas</p>
						<SendIcon id="sendButtonIcon" />
					</div>
				</div>
			</>
		);
	}
}
