// Import the functions you need from the SDKs you need

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// Your web app's Firebase configuration
import * as firebase from "firebase/app";
function firebaseConfig() {
	if (process.env.NODE_ENV === "production") {
		firebase.initializeApp({
			apiKey: "AIzaSyD2-CQA5z1eYCWnX4DQeHN9EpS07b6nP3E",
			authDomain: "dcsappscenter.firebaseapp.com",
			projectId: "dcsappscenter",
			storageBucket: "dcsappscenter.appspot.com",
			messagingSenderId: "1073006500321",
			appId: "1:1073006500321:web:c3cef8e8bc09c34f411aa0",
			measurementId: "G-RX5JBN2RTE",
		});
	} else if (process.env.NODE_ENV === "development") {
		firebase.initializeApp({
			apiKey: "AIzaSyAv3w8KFIxRjzP7cfpeqZSp06vdb8Ttids",
			authDomain: "programacion-web-ce448.firebaseapp.com",
			databaseURL: "https://programacion-web-ce448.firebaseio.com",
			projectId: "programacion-web-ce448",
			storageBucket: "programacion-web-ce448.appspot.com",
			messagingSenderId: "392418438635",
			appId: "1:392418438635:web:a38c64b8fdb04c1a",
		});
	}
}
/* const firebaseConfig = {
	apiKey: "AIzaSyD2-CQA5z1eYCWnX4DQeHN9EpS07b6nP3E",
	authDomain: "dcsappscenter.firebaseapp.com",
	projectId: "dcsappscenter",
	storageBucket: "dcsappscenter.appspot.com",
	messagingSenderId: "1073006500321",
	appId: "1:1073006500321:web:c3cef8e8bc09c34f411aa0",
	measurementId: "G-RX5JBN2RTE",
}; */
export default firebaseConfig;
