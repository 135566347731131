import Paths from "./routes/index.js";
import logo from "./assets/img/logo.svg";
import firebaseconfig from "./functions/firebaseconfig";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import ListAltRoundedIcon from "@mui/icons-material/ListAltRounded";
import Tooltip from "@mui/material/Tooltip";
function App() {
	firebaseconfig();
	function renderLogoutButton() {
		if (window.sessionStorage.getItem("accessToken") !== null) {
			return (
				<Tooltip title="Cerrar Session" placement="right">
					<LogoutRoundedIcon
						style={{
							color: "white",
							marginLeft: "-20vw",
							border: "2px solid white",
							padding: "5px",
							borderRadius: "1.5rem",
						}}
						onClick={() => {
							window.sessionStorage.clear("accessToken");
							window.sessionStorage.clear("email");
							window.location.href = "/";
						}}
					/>
				</Tooltip>
			);
		}
	}
	function renderRenewedsButton() {
		if (
			window.sessionStorage.getItem("accessToken") !== null &&
			window.location.pathname !== "/renovadas"
		) {
			return (
				<Tooltip title="Ver pólizas renovadas" placement="left">
					<ListAltRoundedIcon
						style={{
							position: "relative",
							color: "white",
							right: "-20vw",
							border: "2px solid white",
							padding: "5px",
							borderRadius: "1.5rem",
						}}
						onClick={() => {
							window.location.href = "/renovadas";
						}}
					/>
				</Tooltip>
			);
		}
	}
	return (
		<div id="MainContainerApp">
			<div
				className="cabecera"
				style={{
					backgroundColor: "#0e2130",
					display: "flex",
					alignItems: "center",
				}}
			>
				{renderRenewedsButton()}
				<img
					alt="logo"
					className="logo"
					src={logo}
					onClick={() => {
						window.location.href = "/";
					}}
				/>
				{renderLogoutButton()}
			</div>

			<div className="routesContainer">
				<Paths />
			</div>

			<footer>
				<p>
					© Dominicana Compañía de Seguros
					<br />
					Derechos Reservados
				</p>
			</footer>
		</div>
	);
}
export default App;
