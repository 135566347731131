import React, { Component } from "react";
import TextField from "@mui/material/TextField";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import "../assets/css/insurance.css";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import renderIf from "../functions/renderIf";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import HomeIcon from "@mui/icons-material/Home";
import MuiTable from "../components/MuiTable";

export default class Renewed extends Component {
	constructor(props) {
		super(props);

		this.state = {
			finicio: "",
			ffinal: "",
			text: "",
			datatohandle: [],
			responsejson: [],
			defaultResult: [],
			withbalance: 0,
			textbalancefilter: "Todas",
		};

		this.handleSelectedData = this.handleSelectedData.bind(this);
	}

	handleSelectedData(data) {
		this.setState({ datatohandle: data });
	}

	handlefinicio(event) {
		this.setState({ finicio: event.target.value }, () => {
			this.checkdate();
		});
	}

	handleffinal(event) {
		this.setState({ ffinal: event.target.value }, () => {
			this.checkdate();
		});
	}

	handletext(event) {
		this.setState({ text: event.target.value });
	}

	modalHandle() {
		this.setState({ showModal: !this.state.showModal });
	}

	handleKeyDown(e) {
		if (e.key === "Enter") {
			this.search();
		}
	}
	search() {
		this.setState({
			responsejson: this.state.defaultResult,
		});
		var result = [];
		if (this.state.text.length > 0) {
			for (let x = 0; x < this.state.responsejson.length; x++) {
				const element = this.state.responsejson[x];

				var asegurado = this.state.text.replace(/[^a-zA-Z ]/g, "");
				var myRegExp = new RegExp("" + asegurado, "i");

				if (element.asegurado.match(myRegExp) !== null) {
					result.push(element);
				} else if (element.intermediario.match(myRegExp) !== null) {
					result.push(element);
					// eslint-disable-next-line
				} else if (element.poliza == this.state.text) {
					result.push(element);
				}
				if (x === this.state.responsejson.length - 1) {
					this.setState({ responsejson: result });
				}
			}
		}
	}
	componentDidMount() {
		var token = sessionStorage.getItem("accessToken");
		if (token !== null) {
			let that = this;
			fetch(`${process.env.REACT_APP_DOMAIN}/renovacion/renovadas`, {

				"method": "GET",
				"mode": "cors",
				"credentials": "omit"
			}).then(response => response.json()).then(data => {
				if (data.length > 0)
					var thedata = data.map((poliza) => {
						return {
							poliza: poliza.poliza,
							asegurado: poliza.asegurado,
							intermediario: poliza.intermediario,
							telefono: poliza.telefono,
							estado: poliza.estatusRenovacion,
							inicio: poliza.inicio,
							final: poliza.fechaFinal,
							"prima bruta": poliza.primaBruta,
							impuestos: poliza.impuestos,
							"prima neta": poliza.primaNeta,
							renovacionId: poliza.renovacionId
						}
					})
				that.setState({
					responsejson: thedata,
					defaultResult: thedata,
				})
				that.modalHandle();


			}).catch((error) => {
				console.log(error)
			});;
		} else {
			window.location.href = "/";
		}
	}
	checkdate() {
		this.setState({
			responsejson: this.state.defaultResult,
		})
		if (
			this.state.finicio === "" ||
			this.state.ffinal === ""
		) { } else {

			var result = [];
			var d1 = this.state.finicio.split("-");
			var d2 = this.state.ffinal.split("-");
			var from = new Date(d1[0], parseInt(d1[1]) - 1, d1[2]);  // -1 because months are from 0 to 11
			var to = new Date(d2[0], parseInt(d2[1]) - 1, d2[2]);

			for (let x = 0; x < this.state.responsejson.length; x++) {
				const element = this.state.responsejson[x];
				var fechaFinVigencia = this.state.responsejson[x].final.split("-");
				var checkfechainicio = new Date(fechaFinVigencia[0], parseInt(fechaFinVigencia[1]) - 1, fechaFinVigencia[2]);


				if (checkfechainicio >= from && checkfechainicio < to) {
					result.push(element)
				}
				if (x === (this.state.responsejson.length - 1)) {
					this.setState({ responsejson: result })

				}

			}


		}


	}
	setBalanceFilter() {
		let result = [];
		if (this.state.withbalance === 0) {
			this.setState(
				{
					withbalance: 1,
					textbalancefilter: "Con balance",
					responsejson: this.state.defaultResult,
				},
				() => {
					for (let x = 0; x < this.state.responsejson.length; x++) {
						const element = this.state.responsejson[x];
						// eslint-disable-next-line
						if (element.deuda != 0) {
							result.push(element);
						}
						if (x === this.state.responsejson.length - 1) {
							this.setState({ responsejson: result });
						}
					}
				}
			);
		} else if (this.state.withbalance === 1) {
			this.setState(
				{
					withbalance: 2,
					textbalancefilter: "Sin balance",
					responsejson: this.state.defaultResult,
				},
				() => {
					console.log("Sin balance");
					console.log(this.state.responsejson);
					for (let x = 0; x < this.state.responsejson.length; x++) {
						const element = this.state.responsejson[x];
						// eslint-disable-next-line
						if (element.deuda == 0) {
							result.push(element);
						}
						if (x === this.state.responsejson.length - 1) {
							this.setState({ responsejson: result });
						}
					}
				}
			);
		} else if (this.state.withbalance === 2) {
			this.setState({
				withbalance: 0,
				textbalancefilter: "Todas",
				responsejson: this.state.defaultResult,
			});
		}
	}

	simulatealert() {
		var data = this.state.datatohandle;
		for (let x = 0; x < data.length; x++) {
			const element = data[x];
			fetch(
				`${process.env.REACT_APP_DOMAIN}/resultado/save/renovado/${element.resultRenovacionId}`,
				{
					method: "PUT",
					mode: "cors",
					credentials: "omit",
				}
			);
			if (x === data.length - 1) {
				this.setState({ showModal: !this.state.showModal }, () => {
					setTimeout(() => {
						this.setState({ showalert: true }, () => {
							setTimeout(() => {
								this.setState({ showalert: false }, () => {
									window.location.reload();
								});
							}, 3000);
						});
					}, 1000);
				});
			}
		}
	}
	genreport() {
		this.modalHandle();
	}
	render() {
		return (
			<>
				<div>
					<h1 style={{ color: "#0e3e9b" }}>
						Historico de Renovaciones
					</h1>
				</div>
				<div id="navBar">
					<div id="SearchBar">
						<div
							style={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
							}}
						>
							<TextField
								label="Buscar"
								size="small"
								value={this.state.text}
								onChange={this.handletext.bind(this)}
								margin="dense"
								onKeyDown={this.handleKeyDown.bind(this)}
								className="roundedBorder"
							/>
							<SearchRoundedIcon
								onClick={() => {
									this.search();
								}}
								className="SearchIcon"
							/>
						</div>

						<TextField
							id="finicio"
							label="Fecha de Inicio"
							type="date"
							sx={{ width: 220 }}
							InputLabelProps={{ shrink: true }}
							value={this.state.finicio}
							onChange={this.handlefinicio.bind(this)}
							size="small"
							margin="dense"
							style={{ marginLeft: "1rem" }}
							className="roundedBorder"
						/>

						<TextField
							id="ffinal"
							label="Fecha Final"
							type="date"
							sx={{ width: 220 }}
							InputLabelProps={{ shrink: true }}
							value={this.state.ffinal}
							onChange={this.handleffinal.bind(this)}
							size="small"
							margin="dense"
							style={{ marginLeft: "1rem" }}
							className="roundedBorder"
						/>

						<div
							id="BalanceChecker"
							onClick={() => {
								this.setBalanceFilter();
							}}
						>
							<p>{this.state.textbalancefilter}</p>
						</div>
						{renderIf(
							this.state.withbalance === 0,
							<RadioButtonCheckedIcon
								className="buttoncursor"
								style={{
									color: "#266ED4",
									fontSize: "2rem",
									marginLeft: "-2.5rem",
									marginRight: "1rem",
								}}
								onClick={() => {
									this.setBalanceFilter();
								}}
							/>
						)}
						{renderIf(
							this.state.withbalance === 1,
							<CheckCircleIcon
								className="buttoncursor"
								style={{
									color: "#266ED4",
									fontSize: "2rem",
									marginLeft: "-2.5rem",
									marginRight: "1rem",
								}}
								onClick={() => {
									this.setBalanceFilter();
								}}
							/>
						)}
						{renderIf(
							this.state.withbalance === 2,
							<RadioButtonUncheckedIcon
								className="buttoncursor"
								style={{
									color: "#266ED4",
									fontSize: "2rem",
									marginLeft: "-2.5rem",
									marginRight: "1rem",
								}}
								onClick={() => {
									this.setBalanceFilter();
								}}
							/>
						)}
					</div>

					<HomeIcon
						style={{
							color: "#266ED4",
							fontSize: "2.5rem",
							marginBottom: "0.2rem",
							fontWeight: "ligth",
						}}
						onClick={() => {
							window.location.href = "/polizas";
						}}
						className="buttoncursor"
					/>
				</div>
				<div id="insurancesContainer">
					{
						this.state.responsejson.length > 0 ?
							<MuiTable json={this.state.responsejson} sendSelectedData={(element) => { this.setState({ datatohandle: element }) }} /> :
							<MuiTable sendSelectedData={console.log}
								json={[{
									poliza: "",
									asegurado: "",
									intermediario: "",
									telefono: "",
									estado: "",
									inicio: "",
									final: "",
									"prima bruta": "",
									impuestos: "",
									"prima neta": "",
									renovacionId: ""
								}]}
							/>
					}
				</div>
			</>
		);
	}
}
